.bg {
  background-color: black;
}

.header {
  text-shadow: 2px 2px 2px rgba(000, 000, 000, 0.3);
}

.bg-img {
  filter: blur(8px);
  -webkit-filter: blur(8px);
  opacity: 0.7;
}
